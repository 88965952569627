exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-age-restrictions-tsx": () => import("./../../../src/pages/age-restrictions.tsx" /* webpackChunkName: "component---src-pages-age-restrictions-tsx" */),
  "component---src-pages-bingo-blog-tsx": () => import("./../../../src/pages/bingo-blog.tsx" /* webpackChunkName: "component---src-pages-bingo-blog-tsx" */),
  "component---src-pages-bingo-bonus-tsx": () => import("./../../../src/pages/bingo-bonus.tsx" /* webpackChunkName: "component---src-pages-bingo-bonus-tsx" */),
  "component---src-pages-bingo-gratis-tsx": () => import("./../../../src/pages/bingo-gratis.tsx" /* webpackChunkName: "component---src-pages-bingo-gratis-tsx" */),
  "component---src-pages-cassino-online-brasil-tsx": () => import("./../../../src/pages/cassino-online-brasil.tsx" /* webpackChunkName: "component---src-pages-cassino-online-brasil-tsx" */),
  "component---src-pages-contatos-tsx": () => import("./../../../src/pages/contatos.tsx" /* webpackChunkName: "component---src-pages-contatos-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-copyright-notice-tsx": () => import("./../../../src/pages/copyright-notice.tsx" /* webpackChunkName: "component---src-pages-copyright-notice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

